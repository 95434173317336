import React, { useState, useContext, useEffect } from 'react';
import Modal from '../elements/Modal';
import peachIcon from "../../assets/img/pechicon2.svg"
import unsell from "../../assets/img/unsell.gif"
import selling from "../../assets/vector/Bella-Peach.gif"
import successTransaction from "../../assets/img/successTransaction.webp"
import UserContext from '../../context/userContext/UserContext';

export default function SellNft({ setModal, modal, setReloadData }) {

    const [input, setInput] = useState("")
    const [step, setStep] = useState(1)
    const [error, setError] = useState(false)
    const { contract, account } = useContext(UserContext)

    const handleSubmit = () => {



        setStep(2)

        setError(false)
        contract.ContractCrew.methods[modal.type === "waifu" ? "farmerToFormation" : "tractorToFormation"](modal.item.id).call().then(result => {

            console.log("testing", result)

            if (result === "0") {
                if (modal.item?.forSale) contract[modal.type === "waifu" ? "contractWaifu" : "contractTractor"].methods.unsell(modal.item.id).send({ from: account, }).then(result => { setReloadData(true); setStep(3) }).catch(err => { setStep(1) })
                else contract[modal.type === "waifu" ? "contractWaifu" : "contractTractor"].methods.sell(modal.item.id, (parseFloat(input) * 1000000000000000000).toString()).send({ from: account }).then(result => { setReloadData(true); setStep(3) }).catch(err => { console.error(err); setStep(1) })

            } else { setStep(1); setError(true) }


        })



    }

    const inputFilter = (e) => {
        e.preventDefault();
        let a = e.target.value.replace(/[^0-9\.]+/g, '');
        setInput(a)
    }

    useEffect(() => { if (!modal) { setInput(""); setStep(1) } }, [modal])

    return (<Modal screen={true} modal={modal} setModal={setModal}>
        {step === 1 && <div className="w-screen md:w-[60vh] p-5 rounded-lg">
            <h3 className="text-center text-white text-2xl mb-3"><strong>{modal.item?.forSale ? "UnSell" : "Sell"} {modal.item?.type} #{modal.item?.id}</strong> </h3>

            {!modal.item?.forSale ? <h3 className="text-center text-white text-xl">How many BNB do you want to sell this NFT for? </h3> : <img src={unsell} className=" mx-auto" alt="selling" />}
            {modal.item?.forSale && <h3 className="flex flex-col md:flex-row justify-center text-center text-white text-base md:text-xl mb-5">Your Nft will be<span className="text-[#f7b0bb] font-bold ml-2 mr-2"> removed</span>from the market </h3>}

            {!modal.item?.forSale &&
                <div className="bg-white rounded-full p-1 pl-3 flex overflow-hidden my-2">
                    <input type="number" className="w-full text-xl font-semibold outline-none mr-1" onInput={inputFilter} value={input} placeholder="Digit a number" />
                    <img src={peachIcon} className="w-12" />
                </div>
            }

            {error &&
                <div className="bg-red-300 px-3 py-3 rounded-lg  mb-12 cursor-pointer text-center">
                    <span className="text-red-700 font-bold">
                        This {modal.type === "waifu" ? "Farmer Waifu" : "Tractor"} is currently in a crew
                    </span>
                </div>
            }
            <div className="flex justify-center md:inline-block">
                <button onClick={() => { setModal(false) }} className="rounded-md font-bold w-20 text-white  p-2 mr-4 bg-[#AA2E74]">CANCEL </button>
                <button disabled={input.trim() === "" && !modal.item?.forSale} onClick={handleSubmit} className={`rounded-md text-white p-2  w-20 font-bold ${!modal.item?.forSale && input.trim() === "" ? "bg-[#B2B2B2]" : "bg-[#D9A900]"}`}>{modal.item?.forSale ? "UnSell" : "Sell"} </button>
            </div>
        </div>}

        {step === 2 &&
            <div className="min-h-80 w-[50vw]">
                <h3 className="text-center text-white text-3xl mb-3 pt-4 "> <strong>{modal.item?.forSale ? "UNSELLING..." : "SELLING..."}</strong> </h3>
                <img src={selling} className="h-32 md:h-64 mx-auto" alt="selling" />
            </div>
        }


        {step === 3 && <div className="min-h-80 w-[50vw]">
            <img src={successTransaction} className="h-60 mx-auto" alt="successTransaction" />
            <h3 className="text-center text-white text-xl  mb-3 "> <strong>{modal.item?.forSale ? "NFT unSell" : "NFT offered for sale!"}</strong> </h3>
            <div>
                <button onClick={() => { setModal(false) }} className="rounded-md font-bold w-20 text-white  p-2 mr-4 bg-[#AA2E74]">Close </button>
            </div>

        </div>}

    </Modal>)
}
