import React, { useState, useContext, useEffect } from 'react';
import Modal from '../elements/Modal';
import peachIcon from "../../assets/img/pechicon2.svg"
import burn from "../../assets/img/burn.gif"
import selling from "../../assets/vector/Bella-Peach.gif"
import successTransaction from "../../assets/img/successTransaction.webp"
import UserContext from '../../context/userContext/UserContext';
export default function SellNft({ setReloadData, setModal, modal }) {

    const [step, setStep] = useState(1)
    const [error, setError] = useState(false)
    const { contract, account } = useContext(UserContext)

    useEffect(() => { if (!modal) setStep(1) }, [modal])

    const handleSubmit = () => {

        setStep(2)
        setError(false)

        contract.ContractCrew.methods[modal.type === "waifu" ? "farmerToFormation" : "tractorToFormation"](modal.item.id).call().then(result => {

            if (result === "0") {
                contract[modal.type === "waifu" ? "contractDWGameFarmer" : "contractDWGameTractor"].methods.burn(modal.item.id).send({ from: account }).then(result => { setReloadData(true); setStep(3) }).catch(err => setStep(1))

            }
            else { setStep(1); setError(true) }


        })


    }


    useEffect(() => { if (!modal) { setStep(1) } }, [modal])

    return (<Modal screen={true} modal={modal} setModal={setModal}>
        {step === 1 && <div className="w-[90vw] md:w-[70vw]">
            <h3 className="text-center text-white text-xl md:text-2xl mb-3"> <strong>Burn NFT #{modal.item?.id}</strong> </h3>
            <h3 className="flex flex-col md:flex-row justify-center text-center text-white text-base md:text-xl mb-5">You will receive <span className="text-[#f7b0bb] font-bold ml-2"> $PEACH<img className="inline w-4 md:w-7 mx-2" src={peachIcon} alt="peachicon" /></span> for burning  </h3>
            <img src={burn} className="h-64 mx-auto" alt="selling" />

            {error &&
                <div className="bg-red-300 w-[300px] mx-auto px-3 py-3 rounded-lg  mb-12 cursor-pointer text-center">
                    <span className="text-red-700 font-bold">
                        This {modal.type === "waifu" ? "Farmer Waifu" : "Tractor"} is currently in a crew
                    </span>
                </div>
            }
            <div className="flex justify-center md:inline-block">
                <button onClick={() => { setModal(false) }} className="rounded-md font-bold w-20 text-white  p-2 mr-4 bg-[#AA2E74]">CANCEL </button>
                <button onClick={handleSubmit} className={`rounded-md text-white p-2  w-20 font-bold bg-[#D9A900]`}>BURN </button>
            </div>
        </div>}


        {step === 2 && <div className="h-80 w-[50vw] ">
            <h3 className="text-center text-white text-3xl mb-3 pt-4 "> <strong>BURNING</strong> </h3>
            <img src={selling} className="h-64 mx-auto" alt="selling" />

        </div>}

        {step === 3 && <div className="min-h-80 w-[50vw]">
            <img src={successTransaction} className="h-60 mx-auto" alt="successTransaction" />
            <h3 className="text-center text-white text-xl  mb-3 "> <strong>NFTs burned!</strong> </h3>

            <div> <button onClick={() => { setModal(false) }} className="rounded-md font-bold w-20 text-white  p-2 mr-4 bg-[#AA2E74]">Close </button></div>
        </div>}

    </Modal>)
}
