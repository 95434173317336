
import React, { useState, useContext, useEffect } from 'react'
import { useForm } from "react-hook-form";

import start1 from "../../assets/vector/start1.svg"
import start2 from "../../assets/vector/start2.svg"
import start3 from "../../assets/vector/start3.svg"
import start4 from "../../assets/vector/start4.svg"
import start5 from "../../assets/vector/start5.svg"
import loadIcon from "../../assets/img/loadingIcon.webp";
import TractorCardItem from '../elements/TractorCardItem';
import waifuMintIcon from "../../assets/vector/waifuMintIcons/waifuMintIcon.svg";

import MintQuick from '../elements/mintQuick';
import PaginationComponent from '../elements/PaginationComponent';
import UserContext from '../../context/userContext/UserContext';
import SellNft from '../SellNft/SellNft';
import BurnNft from '../BurnNft/BurnNft';
export default function MarketTractor() {

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [listItems, setListItems] = useState(null)
    const [listItemsFilter, setListItemsFilter] = useState(null)
    const [selectStar, setSelectStar] = useState(0)
    const [modalNft, setModalNft] = useState(false)
    const [modalNftBurn, setModalNftBurn] = useState(false)
    const { contract, account, chainId } = useContext(UserContext)
    const [reloadData, setReloadData] = useState(true);
    const [filtered, filtered_action] = useState(false);

    useEffect(() => {

        let listLocalStorage = JSON.parse(localStorage.getItem("TractorsStorage"))

        if (listLocalStorage && listLocalStorage.account === account) {
            setListItems(listLocalStorage.listTractor || null)
            setListItemsFilter(listLocalStorage.listTractor || [null])
        }
    }, [])

    useEffect(() => { if (!reloadData) setReloadData(true) }, [chainId])

    useEffect(() => {
        if (contract && account && reloadData) {

            setReloadData(false)
            contract.contractTractor.methods.getTractorIdsOf(account).call().then(result => {

                const getAllTractor = []

                result.map(element => { if (element !== "1000000000000000000") return getAllTractor.push(contract.contractTractor.methods.getTractor(element).call()) })
                Promise.all(getAllTractor).then(result => {
                    setListItems(result.reverse())
                    setListItemsFilter(result.reverse())
                    localStorage.setItem("TractorsStorage", JSON.stringify({ listTractor: result.reverse(), account }));
                }).catch(err => {

                    setListItems([])
                    setListItemsFilter([])
                })
            })
        }
    }, [contract, account, reloadData])


    const handleFilterStart = (value) => {
        filtered_action(true)
        if (value === "all") return setListItemsFilter(listItems)
        setListItemsFilter(listItems.filter(element => element.level === value))
    }

    const HaventTractors = () => (
        <div className="grid grid-rows-mint justify-center text-center mt-8">
            <img src={waifuMintIcon} alt="waifu havent mint" className="w-52 mx-auto self-center"></img>
            <span className="font-bold text-base text-black">It seems that you haven't any tractor at this moment!</span>
            <span className="text-sm text-black">After you have minted your Tractors will appear here</span>

            <div className="p-3 bg-[#ccb35078] w-34 rounded-md mx-auto mt-5">

                <button onClick={() => { if (typeof window !== 'undefined') window.location.href = "/mint" }} className="bg-[#D9A900] w-34 px-3 mx-auto text-white font-bold text-base rounded-md">
                    GO TO MINT
                </button>
            </div>
        </div>
    );

    const stars_list = ['#', start1, start2, start3, start4, start5];
    const Not_Filter = () => (
        <div className="grid grid-rows-mint justify-center text-center mt-8">
            <img src={waifuMintIcon} alt="waifu havent mint" className="w-52 mx-auto self-center"></img>
            <div className="font-bold text-lg text-black flex justify-center items-center">
                <span className="mr-2">You don't have Tractors {(selectStar !== 0) && 'from'}</span>
                {(selectStar !== 0) && <img className='w-10' src={stars_list[selectStar]} alt="star" />}
            </div>
            <span className=" text-base text-black">After you have minted your Farmer Tractors will appear here</span>
            <div className="p-3 bg-[#F0C1DC] w-34 rounded-md mx-auto mt-5">

                <button onClick={() => { if (typeof window !== 'undefined') window.location.href = "/mint" }} className="bg-[#AA2E74] w-34 px-3 mx-auto text-white font-bold text-base rounded-md">
                    GO TO MINT
                </button>
            </div>
        </div>
    );

    return (
        <div className="p-5 " id="modal_blur">
            <SellNft setReloadData={setReloadData} modal={modalNft} setModal={setModalNft} />
            <BurnNft setReloadData={setReloadData} modal={modalNftBurn} setModal={setModalNftBurn} />

            {listItemsFilter &&
                <section className="flex flex-wrap justify-center md:justify-between ">
                    {/*<MintQuick mint_type="tractor" set_reload_data={setReloadData} items={listItems} />*/}
                    <div>
                        <h3 className="pb-2 text-lg mt-12 md:mt-0 text-center md:text-left"> <strong>Filter by Tractor Rarity:</strong>  </h3>
                        <div className="flex justify-center items-center ">
                            <button onClick={() => { handleFilterStart("all"); setSelectStar(0) }} className={`${selectStar == 0 ? "bg-primaryDark" : "bg-background"} w-10 rounded-md text-white h-10  active:scale-90`}>All</button>
                            <img onClick={() => { handleFilterStart("1"); setSelectStar(1) }} src={start1} alt="start-1" className={`${selectStar == 1 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} />
                            <img onClick={() => { handleFilterStart("2"); setSelectStar(2) }} src={start2} alt="start-2" className={`${selectStar == 2 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} />
                            <img onClick={() => { handleFilterStart("3"); setSelectStar(3) }} src={start3} alt="start-3" className={`${selectStar == 3 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} />
                            <img onClick={() => { handleFilterStart("4"); setSelectStar(4) }} src={start4} alt="start-4" className={`${selectStar == 4 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} />
                            <img onClick={() => { handleFilterStart("5"); setSelectStar(5) }} src={start5} alt="start-5" className={`${selectStar == 5 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} />
                        </div>
                    </div>
                </section>
            }

            {!listItemsFilter
                ? <img src={loadIcon} className="m-auto mt-[8rem] w-16 animate-spin" alt='loading' />
                : <section className=" py-10"> {listItemsFilter.length > 0 ? <PaginationComponent setModalNftBurn={setModalNftBurn} setModalNft={setModalNft} item={"tractorInventory"} listItems={listItemsFilter} paginationCant={12} /> : <> {filtered ? <Not_Filter /> : <HaventTractors />} </>}  </section>
            }
        </div>
    )
}